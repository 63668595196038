import axios from "axios";

export const autocomplete = async (search) => {
	try {
		let response = await axios.post(
			"https://nomera.sigma-studio.pp.ua/barcode/autocomplete.php",
			search
		);
		return response.data;
	} catch (error) {
		//console.log(error);
	}
};
